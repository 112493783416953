/*
  import { hoaContactsStore } from '@/services/HoaContacts/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import _get from 'lodash/get'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await hoaContactsStore.dispatch ('getHoaContactsList', {
      hoaId
    });
  */

  async getHoaContactsList({}, { hoaID }) {
    console.log('in getHoaContactsList...' + hoaID)
    try {
      const results = await kms.get(`/HoaContacts/HoaContact/List?hoaID=${hoaID}`)

      if (isDebug == true) console.debug('getHoaContactsList log=' + JSON.stringify(results))

      return {
        list: results.results
      }
    } catch (exception) {
      notifyProblem(`The HOA Contacts list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async deleteHoaContacts({ dispatch }, { hoaContactID, done }) {
    try {
      const path = `/HoaContacts/HoaContact/${hoaContactID}?forceDelete=false`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted the hoa contact.`)
        done()
      } else {
        notifyError('There was a problem deleting the hoa contact.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem deleting the hoa contact.`)
      console.error(exception)
    }
  },

  async addHoaContact({ dispatch }, { payload, done }) {
    if (isDebug == true) console.debug('...in addHoaContact')
    try {
      const results = await kms.post(`/HoaContacts/HoaContact`, {
        hoaID: payload.hoaID,
        description: payload.description,
        phone: payload.phone,
        title: payload.title,
        email: payload.email
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.hoaContactID > 0) {
        notifyMessage(`Successfully added the hoa contact.`)
        done()
      } else {
        notifyError('There was a problem adding this hoa contact.')
      }
    } catch (exception) {
      notifyError(`There was a problem adding this hoa contact.`)
      console.error(exception)
    }
  },

  async updateHoaContact({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/HoaContacts/HoaContact`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.hoaContactID > 0) {
        notifyMessage(`Successfully updated this hoa contact.`)
        done()
      } else {
        notifyError('There was a problem updating this hoa contact.')
      }
    } catch (exception) {
      notifyError(`There was a problem processing the update for this hoa contact.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const hoaContactsStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
