<template>
  <div hoa-contact-modal id="hoaContactModal" class="section">
    <div class="container">
      <div class="is-size-4">{{ `${buttonText} Hoa Contact` }}</div>
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div class="is-12 pt-5">
                  <valid-input
                    style="width:50px !important;"
                    name="Title"
                    label="Title"
                    type="text"
                    vid="Title"
                    placeholder="Title"
                    spellcheck="true"
                    aria-label="Title"
                    rules="required|max:100"
                    class="is-small"
                    v-model="formData.title"
                  />
                </div>
                <div class="is-12 pt-5">
                  <valid-input
                    style="width:50px !important;"
                    name="Email"
                    label="Email"
                    type="text"
                    vid="Email"
                    placeholder="Email"
                    spellcheck="true"
                    aria-label="Email"
                    rules="max:100"
                    class="is-small"
                    v-model="formData.email"
                  />
                </div>
                <div class="is-12 pt-5">
                  <valid-input
                    style="width:50px !important;"
                    name="Phone"
                    label="Phone"
                    maxlength="14"
                    type="phone"
                    vid="Phone"
                    placeholder="Phone"
                    spellcheck="true"
                    aria-label="Phone"
                    rules="max:14"
                    class="is-small"
                    v-model="formData.phone"
                  />
                </div>
                <div class="is-12 pt-5">
                  <valid-input
                    style="width:50px !important;"
                    name="Description"
                    label="Description"
                    type="textarea"
                    vid="Description"
                    placeholder="Description"
                    spellcheck="true"
                    aria-label="Description"
                    rules="required|max:8000"
                    class="is-small"
                    v-model="formData.description"
                  />
                </div>
                <div class="pt-6 pl-2">
                  <button
                    type="submit"
                    class="button is-primary is-rounded"
                    tests-id="update-work-order-button"
                  >
                    {{ buttonText }}
                  </button>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import ValidInput from '@/components/inputs/ValidInput'

export default {
  name: 'HoaContactModal',
  props: {
    hoaContact: Object
  },

  components: {
    ValidationObserver,
    ValidationProvider,
    ValidInput
  },

  mounted() {
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,
  methods
}
</script>
<style lang="scss"></style>
