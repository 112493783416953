export function data() {
  return {
    isDebug: true,
    isOpen: false,
    loading: true,
    isFetching: false,
    openOnFocus: true,
    buttonText: 'Add',
    formData: {
      description: '',
      title: '',
      email: '',
      phone: ''
    }
  }
}
