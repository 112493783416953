<template>
  <PageContent>
    <div class="pb-1">
      <b-button
        @click.prevent="createHoaContactModal()"
        type="is-primary"
        size="is-small"
        rounded
        :style="{
          ...(breakpoint === 'desktop'
            ? {}
            : {
                width: '100%'
              })
        }"
      >
        Add Hoa Contact
      </b-button>
    </div>
    <div>
      <ModernTable
        :region="region"
        :filters="filters"
        :rows="rows"
        :columns="columns"
        :pagination="{
          perPage: 12
        }"
        :loading="loading"
      />
    </div>
    <Modal :toggle.sync="toggle">
      <HoaContactModal :hoaContact="selectedHoaContact"> </HoaContactModal>
    </Modal>
  </PageContent>
</template>

<script>
import PageContent from '@/containers/PageContent'
import ModernTable from '@/components/tables/Modern/Table'
import HoaContactModal from './components/hoaContactModal'
import Modal from '@/components/Modal'
import { mapState, mapGetters } from 'vuex'
import { methods } from '../hoaContacts/keys/methods'
import { data } from '../hoaContacts/keys/data'

export default {
  name: 'HoaContactList',
  components: {
    PageContent,
    ModernTable,
    HoaContactModal,
    Modal
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser']),
      breakpoint: state => (state && state.win ? state.win.breakpoint : null)
    })
  },

  data,

  methods,

  async mounted() {
    await this.reload()
  },

  watch: {
    statusFilter: 'reload',
    hoaId() {
      this.reload()
    },
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  i18n: {
    messages: {
      en: {
        hoaContacts: {
          title: 'HOA Contacts'
        }
      }
    }
  }
}
</script>

<style></style>
