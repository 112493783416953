import { hoaContactsStore } from '@/services/HoaContacts/store'
import { notifyError } from '@/services/notify'
import $ from 'jquery'
import _get from 'lodash/get'

export const methods = {
  async reload() {
    console.debug('in reload()')

    this.hoaContactID = _get(this, '$route.params.id', null)

    if (this.hoaContact) {
      this.formData.description = this.hoaContact.description
      this.formData.phone = this.hoaContact.phone
      this.formData.title = this.hoaContact.title
      this.formData.email = this.hoaContact.email
      this.buttonText = 'Update'
    }
  },

  open() {
    this.isOpen = true
    this.loading = false
  },

  closeModal() {
    this.$emit('update:toggle', (this.documentToggle = !this.documentToggle))

    // auto-close modal
    $('#clickclose').click()
  },

  async onFormSubmit() {
    if (!this.formData) {
      notifyError('There was a problem processing this hoa contact.')
      return
    }

    if (!this.formData.email && !this.formData.phone) {
      notifyError('At least an email address or phone number is required.')
      return
    }

    this.loading = true

    try {
      const payload = {
        hoaID: this.hoaId,
        description: _get(this.formData, 'description', ''),
        phone: _get(this.formData, 'phone', ''),
        title: _get(this.formData, 'title', null),
        email: _get(this.formData, 'email', null)
      }

      console.log('payload=' + JSON.stringify(payload))

      if (
        this.hoaContact &&
        this.hoaContact.hoaContactID !== undefined &&
        this.hoaContact.hoaContactID
      ) {
        payload.hoaContactID = this.hoaContact.hoaContactID

        await hoaContactsStore.dispatch('updateHoaContact', {
          payload,
          done: () => {
            this.closeModal()
          }
        })
      } else {
        await hoaContactsStore.dispatch('addHoaContact', {
          payload,
          done: () => {
            this.closeModal()
          }
        })
      }
    } catch (e) {
      notifyError(e)
    }
    this.loading = false
  }
}
